import React, { useState } from 'react';
import { Layout, Input, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;
const { Header } = Layout;

const AppHeader = ({ onToggleSider, siderCollapsed }) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearch = value => {
    console.log(value); // Remplacer par votre logique de recherche
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };

  return (
    <div style={{ background: '#ffffff', display: 'flex', justifyContent: 'center' }}>
      <Header style={{ background: '#ffffff', padding: 0, maxWidth: '1000px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        
        {/* Bouton sur la gauche */}
        <Button
          type="text"
          icon={siderCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={onToggleSider}
          style={{
            fontSize: '16px',
            width: 80,
            height: 80,
          }}
        />
  
        {/* Barre de recherche au milieu */}
        <Search

          placeholder="N° de pièce"
          
          size="large"
          
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          onPressEnter={() => onSearch(searchValue)}
          style={{ flexGrow: 1, margin: '0 20px', maxWidth: '400px' }} // Ajout de marges pour l'espacement
        />
  
        {/* Logo sur la droite */}
        <img onClick={handleClick} src="/LogoOEM.png" alt="Logo" style={{ cursor:'pointer', height: 'auto', width: '140px' }} />
  
      </Header>
    </div>
  );
        }
  

export default AppHeader;
