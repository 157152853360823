import React from 'react';
import logo from './logo.svg';
import SearchPage from './searchPage/SearchPage'
import ComparePage from './comparePage/comparePage'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Button, Flex } from 'antd';
import './App.css';
import './theme.css';


function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route path="/" element={<SearchPage />} />
        <Route path="/prices/:partNumber" element={<ComparePage />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
