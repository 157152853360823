import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import PartCard from './partCard';
import { Layout, Drawer, Menu,Spin, Divider } from 'antd';
import { Input, } from 'antd';
import AppHeader from './AppHeader';
import { HomeOutlined } from '@ant-design/icons';



const { Search } = Input;
const { Header, Content, Footer, Sider } = Layout;


const ComparePage = () => {
  const [jobResults, setJobResults] = useState([]);
  const [areAllJobsFinished, setAreAllJobsFinished] = useState(false);
  const location = useLocation();
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { partNumber } = useParams()
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleMenuClick = ({ key }) => {
    if (key === 'mainMenu') {
      navigate('/'); // Redirige vers la racine du projet
    }
    setDrawerVisible(false);
  };


  const fetchJobStatus = () => {
    setJobResults([])
    setIsLoading(true)
    const apiUrl = `https://api.oemcompare.com/scrape?partNumber=${partNumber}`;

    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          setIsLoading(false)
          throw new Error('Network response was not ok');

        }
        return response.json();
      })
      .then(data => {
        const jobIds = data.job_ids.join('%2C'); // Rejoindre les IDs avec '%2C'
        checkJobStatus(jobIds);
      })
      .catch(error => {
        setIsLoading(false);
        console.error('There has been a problem with your fetch operation:', error);
      });
  };

  let intervalId = null;

  const checkJobStatus = (jobIds) => {

    console.log(isLoading)

    if (intervalId) clearInterval(intervalId);
    const statusUrl = `https://api.oemcompare.com/status/${jobIds}`;

    intervalId = setInterval(() => {
      console.log(isLoading)
      fetch(statusUrl)
        .then(response => response.json())
        .then(data => {
          if (data.data && data.data.length > 0) {
            setJobResults(data.data);
            if (data.areAllJobsFinished) {
              setIsLoading(false);
              setAreAllJobsFinished(true);
              clearInterval(intervalId);
            }
          }
        })
        .catch(error => {
          setIsLoading(false)
          console.error('Fetch error:', error);
          clearInterval(intervalId);
        });
    }, 2000);
  };

  useEffect(() => {
    if (  partNumber && !isLoading) { // Ajouter une condition pour vérifier partNumber
      fetchJobStatus();
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      setIsLoading(false) // Effacer l'intervalle lors du nettoyage
    };
  }, [ partNumber]);



  return (
    <><div>
      <Layout>
        <AppHeader onToggleSider={toggleDrawer} siderCollapsed={siderCollapsed} />
        <Layout>

          <Drawer
            title="Menu"
            placement="left"
            onClose={toggleDrawer}
            open={drawerVisible}
            width={300}
          >
            <Menu onClick={handleMenuClick}>
              <Menu.Item key="mainMenu" icon={<HomeOutlined />}>
                Menu Principal
              </Menu.Item>
              {/* Autres éléments de menu ici */}
            </Menu>
          </Drawer>


          <Content style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center' }}>

            <div style={{ maxWidth: '1000px' }}>
              <h1>Résultats pour: {partNumber} </h1>
              

              <Divider style={{ margin: '2px' }} />
              <div>
                {isLoading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin size="large" />
                  </div>):(<div></div>)}
              </div>

              <div>
                {jobResults.length > 0 && jobResults.map((jobResult, index) => (
                  <PartCard key={index} dataPart={jobResult} />
                ))}
              </div>
            </div>
          </Content>
        </Layout>

      </Layout>

    </div>
    </>
  );
};

export default ComparePage;
