import './MobilePartCard.css'
import { Divider } from 'antd';
import { Button, Flex } from 'antd';
import React, { useState } from 'react';
import { Table } from 'antd';
import { DownCircleFilled } from '@ant-design/icons'


const MobilePartCard = ({ dataPart }) => {

  console.log(dataPart)
  const cheapestVendor = dataPart.cheapestVendor;
  const allVendors = dataPart.allVendors;
  const [showVendors, setShowVendors] = useState(false);

  const toggleVendors = () => {
    setShowVendors(!showVendors);
  };


  const columns = [
    {
      title: '',
      dataIndex: 'vendorLogo',
      key: 'vendorLogo',
      render: logoUrl => <img src={logoUrl} alt="logo" style={{ width: 70 }} />,
    },

    {
      title: 'Prix',
      dataIndex: 'price',
      key: 'price',
      render: price => `${price} €`,
    },
    {
      title: 'Acheter',
      dataIndex: 'url',
      key: 'action',
      render: (_, record) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          <Button>Acheter</Button>
        </a>
      ),
    },
  ];
  const handleButtonClick = (key) => {
    console.log('Bouton cliqué pour le vendeur avec la clé:', key);
    // Ajoutez ici la logique de traitement du clic sur le bouton
  };
  return (

    <div className="part-card">
      <div className='part-info'>
        <div className="part-image">
          <img
            src={cheapestVendor.logoBrandLink}
            alt={cheapestVendor.brand}
            className='logoBrandImage'
          />
          <img
            src={cheapestVendor.imageLink}
            alt=""
            className='partImage'
          />
        </div>
        <div className="part-details">

          <h3>{cheapestVendor.partName}</h3>
          <p className="price">{cheapestVendor.price} €</p>
          <p className='ref-brand'>Ref: {cheapestVendor.refBrand}</p>
          <Divider style={{ margin: '2px' }} />
          <div className='properties'>
            <p >Propriété 1: XXmm</p>
            <p>Propriété 2: XXmm</p>
          </div>
          <Button type='primary' className="buy-button" href={cheapestVendor.url} target="_blank" >

            Acheter sur {cheapestVendor.vendorName}
          </Button>
        </div>

      </div>
      <Divider style={{ margin: '2px' }} />

      <a onClick={toggleVendors} className="see-all-vendors">
        <p className='see-all-vendors-text'> Voir tous les vendeurs</p>
        <DownCircleFilled className='see-all-vendors-icon' />
      </a>




      {showVendors && (

        <div>

          <Table
            dataSource={allVendors}
            columns={columns}
            pagination={false}
            className="all-vendor-table"
          />



        </div>
      )}

    </div>
  );
};

export default MobilePartCard;