import React from 'react';
import { useMediaQuery } from 'react-responsive';
import MobilePartCard from './MobilePartCard'
import DesktopPartCard from './DesktopPartCard'

const PartCard = ({ dataPart }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <div className="part-card-container" style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
      {isMobile ? (
        <MobilePartCard dataPart={dataPart} />
      ) : (
        <DesktopPartCard dataPart={dataPart} />
      )}
    </div>
  );
};


export default PartCard;