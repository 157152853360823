import React, { useState } from 'react';
import './SearchPage.css'; // Assurez-vous de créer également un fichier CSS correspondant.
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Flex } from 'antd';
import { Space } from 'antd';
import { Divider } from 'antd';
import { FaCarAlt, FaCarBattery, FaShoppingCart    } from "react-icons/fa";
import { SlMagnifier } from "react-icons/sl";
import { BorderOutlined } from '@ant-design/icons';

const { Search } = Input;

const SearchBox = () => {

  // Création de l'instance de useNavigate
  const navigate = useNavigate();
  const onSearch = value => {
    if (!value) {
      return;
    }

    navigate(`/prices/${value}`)

  };

  const handleClick = () => {
    navigate('/prices/13321702632');
  };


  return (
    <div className="landing-container">
      <div className="header">
        {/* Remplacez 'path-to-your-logo.png' par le chemin vers votre propre logo */}
        <img src="/LogoOEM.png" alt="Your Logo" className="logo" />
      </div>
      <div className="content">
        <h1>Achetez vos pièces au meilleur prix.</h1>
        <p>Choisissez facilement vos pièces grâce aux diagrammes constructeurs et comparez les prix parmis les dizaines de sites disponibles.</p>
        <Search
          placeholder="N° de pièce"
          allowClear
          enterButton="Voir les prix"
          size="large"
          onSearch={onSearch}

        />

        <small>Essayez ce numéro : <a href="/prices/13321702632" onClick={(e) => { e.preventDefault(); handleClick(); }}>13321702632</a></small>
      </div>
      <Divider></Divider>
      <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
        <div className="cardFeatures">
          <FaCarAlt style={{ width: '50px', height: '50px' }} />
          <h3>1 - Choisissez votre véhicule</h3>
          <p>Sélectionnez votre modèle parmi toutes les marques disponibles dans le catalogue.</p>
        </div>

        <div className="cardFeatures">
          <FaCarBattery style={{ width: '50px', height: '50px' }} />
          <h3>2 - Sélectionnez votre pièce</h3>
          <p>Utilisez les vues éclatées constructeurs pour identifier précisement votre numéro de pièce OEM</p>
        </div>

        <div className="cardFeatures">
          <SlMagnifier   style={{ width: '50px', height: '50px' }} />
          <h3>3 - Comparez les prix</h3>
          <p>Etudier en un clin d'oeuil les prix proposées par les différents revendeurs en fonction de la marque de la pièce.</p>
        </div>

        <div className="cardFeatures">
          <FaShoppingCart  style={{ width: '50px', height: '50px' }} />
          <h3>4 - Achetez</h3>
          <p>Achetez directement chez les revendeurs habituels en un seul clic.</p>
        </div>


      </div>

    </div>

  );
};

export default SearchBox;
